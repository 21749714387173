export const TX_NOTIFICATION_TYPES = {
  STANDARD_TX: 'STANDARD_TX',
  CONFIRMATION_TX: 'CONFIRMATION_TX',
  CANCELLATION_TX: 'CANCELLATION_TX',
  WAITING_TX: 'WAITING_TX',
  SETTINGS_CHANGE_TX: 'SETTINGS_CHANGE_TX',
  NEW_SPENDING_LIMIT_TX: 'NEW_SPENDING_LIMIT_TX',
  REMOVE_SPENDING_LIMIT_TX: 'REMOVE_SPENDING_LIMIT_TX',
  SAFE_NAME_CHANGE_TX: 'SAFE_NAME_CHANGE_TX',
  OWNER_NAME_CHANGE_TX: 'OWNER_NAME_CHANGE_TX',
  ADDRESS_BOOK_NEW_ENTRY: 'ADDRESS_BOOK_NEW_ENTRY',
  ADDRESS_BOOK_EDIT_ENTRY: 'ADDRESS_BOOK_EDIT_ENTRY',
  ADDRESS_BOOK_DELETE_ENTRY: 'ADDRESS_BOOK_DELETE_ENTRY',
  ADDRESS_BOOK_EXPORT_ENTRIES: 'ADDRESS_BOOK_EXPORT_ENTRIES',
  ADDRESS_BOOK_IMPORT_ENTRIES: 'ADDRESS_BOOK_IMPORT_ENTRIES',
}
